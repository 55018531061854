body{
  font-family: "Poppins", serif !important;
  font-weight: 400;
}
.main_page{
  background-color: #F2F4F8;
}
.ff-poppins{
  font-family: "Poppins", serif !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield; /* For Firefox */
}

/* ======================responsive table css==================================== */

.css-1f21cux{
  overflow: visible !important;
}
.display-one th{
  background-color: black !important;
}
.display-one tr:nth-child(even){
  background-color: var(--success-20-color) !important;
  color: black !important;
}
.display-one tr:nth-child(odd){
  background-color: white !important;
  color: black !important;
}

.tr-text-white td{
  color: white !important;
}

.tr-text-black-10 td{
  color: skyblue !important;
}
.cursor-disable{
  cursor: no-drop;
}
.border-1px-secondary-100{
  border: 1px solid var(--secondary-100-color);
}

.placeholder-secondary-100::placeholder{
  color: var(--text-secondary-100);
}

.border-radius-8px{
  border-radius: 8px;
}
.table-wrapper .rdt_TableRow {
  position: relative;
  z-index: 1 !important; 
}

.hover-card:hover .hover-card-color{
  background-color: var(--secondary-100-color);
  color: white !important;
}
.hover-card:hover .text-white-hover{
  color: white !important;
}
.white-space-nowrap{
  white-space: nowrap !important;
}

.scroll{
  position: relative;
  left:0%;
  animation-name: scrolls;
  animation-duration: 15s;
  animation-iteration-count: infinite;
}

@keyframes scrolls {
  100% {left: -100%;}
}

.text-overflow-ellipsis{
  text-overflow: ellipsis !important;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper{
  background-color: #78787800 !important;
}
.report-tabs {
  list-style-type: none;
}
.report-tabs li {
  display: inline-block;
  margin-right: 10px;
}

.row .bg-f8:nth-child(odd) {
  background-color: #f8f8f8 !important;
}

/* =================================custom scroll====================== */
.custom_scroll{
  transition: all 0.5s;
}
/* width */
.custom_scroll::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.custom_scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  /* border-radius: 10px; */
}
 
/* Handle */
.custom_scroll::-webkit-scrollbar-thumb {
  background: var(--secondary-50-color); 
  border-radius: 10px; 
}

/* Handle on hover */
/* .custom_scroll::-webkit-scrollbar-thumb:hover {
  background: #06ace4; 
} */