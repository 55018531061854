.filter-container {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}

.filter-container label {
    margin-right: 10px;
    font-weight: bold;
}

.filter-container select {
    width: 200px; /* Increase the width */
    padding: 5px;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 14px;
    text-align: center;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    transition: border-color 0.3s ease;
}

.filter-container select:focus {
    border-color: #007bff;
    outline: none;
}