@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root{
  --secondary-100-color:#28326F;
  --prime-100-color:#2490EA;
  --prime-20-color:#DFF0FF;
  --info-100-color:#0DCAF0;
  --success-100-color:#198754;
  --warning-100-color:#FFC107;
  --danger-100-color:#DC3545;
  --prime-50-color:#7DC1FA;
  --info-20-color:#C7ECF4;
  --success-20-color:#E2F6ED;
  --warning-20-color:#F7EBC5;
  --danger-20-color:#F0CFD2;
  --secondary-50-color:#5D6BC3;
  --secondary-20-color:#CCD2F8;
  --black-10-color:#DFDFDF;
  --black-20-color:#C8C8C8;
  --black-30-color:#B2B2B2;
  --black-40-color:#9B9B9B;
  --black-50-color:#848484;
  --black-60-color:#6E6E6E;
  --black-70-color:#585858;
  --black-80-color:#414041;
  --black-90-color:#2B2A2A;
  --black-100-color:#141313;
  --white-color:#FFFFFF;
  --Link-color:#5611EB;
}

th, td {
  white-space: nowrap;
}

.bg-prime-20{
  background: var(--prime-20-color);
}

.bg-secondary-100{
  background: var(--secondary-100-color);
}
.bg-black-10{
  background: var(--black-10-color);
}
.bg-success-100{
  background: var(--success-100-color);
}
.bg-prime-100{
  background: var(--prime-100-color) !important;
}
.bg-info-20{
  background: var(--info-20-color);
}
.bg-success-20{
  background: var(--success-20-color);
}
.bg-warning-20{
  background: var(--warning-20-color);
}
.bg-danger-20{
  background-color: var(--danger-20-color);
}
.bg-secondary-20{
  background-color: var(--secondary-20-color);
}
.bg-hover-prime-20:hover{
  background-color: var(--prime-20-color);
}
.bg-active-prime-100{
  background-color: var(--prime-100-color) !important;
  color: white !important;
}
.fs-10px{
  font-size: 10px;
}
.fs-12px{
    font-size: 12px;
}
.fs-14px{
    font-size: 14px;
}
.fs-16px{
  font-size: 16px;
}
.fs-18px{
    font-size: 18px;
}
.fs-20px{
    font-size: 20px;
}
.fs-24px{
    font-size: 24px;
}
.fw-600{
    font-weight: 600;
}
.fw-500{
    font-weight: 500;
}
.h-60px{
    height: 60px;
}
.active{
    background-color: var(--secondary-100-color);
    color: white;
}
.text-success-100{
    color: var(--success-100-color);
}
.text-warning-success-100{
    color: var(--warning-100-color);
}
.text-prime-100{
    color: var(--prime-100-color);
}
.text-secondary-100{
  color: var(--secondary-100-color);
}
.text-info-100{
  color: var(--info-100-color);
}
.text-danger-100{
  color: var(--danger-100-color);
}
.text-link{
    color: var(--Link-color);
}
.text-secondary-50{
  color: var(--secondary-50-color);
}
.text-warning-100{
  color: var(--warning-100-color);
}
.cursor-pointer{
    cursor: pointer;
}
.transition{
    transition: all 0.5s;
}
.rotate{
    rotate: -180deg;
}
.gap-8px{
    gap: 8px;
}
.border-prime-100{
    border: 6px solid var(--prime-100-color);
}
.border-prime-20{
    border: 2px solid var(--prime-20-color);
}
.border-secondary-100{
    border: 6px solid var(--secondary-100-color);
}
.border-secondary-100-8px{
    border: 8px solid var(--secondary-100-color);
}
.border-bottom-only{
  border-bottom: 2px solid var(--black-20-color) !important;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  outline: none;
}
.box-shadow:hover{
  box-shadow: 0 10px 14px #00000026;
}
.submenu-hover:hover{
  background: var(--prime-20-color) !important;
}
.submenu-hover-collpase:hover{
  background: var(--prime-20-color) !important;
  color: var(--prime-100-color) !important;
}
.submenu-active:active{
  background: var(--prime-100-color) !important;
  color: white !important;
}

.border-start-sub{
  border-left: 5px solid var(--secondary-100-color);
}
.bg-light-blue{
  background: rgba(0, 174, 255, 0.068);
}
.bg-prime-100-active:active{
  background: var(--prime-100-color);
}
.border-b-warning-100{
  border-bottom: 2px solid var(--warning-100-color);
  border-top: 0;
  border-left: 0;
  border-right: 0;
}
.border-b-secondary-20{
  border-bottom: 2px solid var(--secondary-20-color);
  border-top: 0;
  border-left: 0;
  border-right: 0;
}
.border-Prime-100{
  border: 2px solid var(--prime-100-color);
}
.hover-scale-1-2:hover{
  transform: scale(1.2);
}
.transition-3s{
  transition: all 0.3s;
}

.select {
  appearance: none; /* Standard */
  -webkit-appearance: none; /* Safari/Chrome */
  -moz-appearance: none; /* Firefox */
  background-color: transparent;
  border: 0;
  color: white;
  font-weight: 500;
  font-size: 16px;
  padding-right: 20px; /* Ensures padding for custom icons if needed */
}

@media (max-width:992px){
  .mobile-hide{
    display: none !important;
  }
}