.dashboardBox{
    background-color: #ffffff;
    padding: 10px;
    justify-content: space-between;
    margin: 10px;
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
}

.eac_grid{
    background-color: #EBF0FC;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    min-width: 212px;
    gap: 10px;
    min-height: 134px;
    margin: 5px;
}

.amount_icon_wrapper{
    display: flex;
    gap: 10px;
    align-items: center;
}

.grid_icon{
    background-color: #6B94EA;
    border-radius: 50%;
    color: #fff;
    width: 42px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
}
.grid_icon svg{
    vertical-align: middle;
}

.amountText{
    color: #3B3E44;
    font-size: 24px;
    font-weight: 500;
    font-family: "Inter" !important;
}

.grid_title{
    color: #3B3E44;
    font-size: 14px;
    font-weight: 600;
    font-family: "Inter" !important;
}

.grid_caption{
    color: #6B94EA;
    font-size: 12px;
    font-weight: 500;
    font-family: "Inter" !important;
}

.add_interesetBtn{
    cursor: pointer;
}
.dashboardStatCard{
    border-radius: 10px;
    margin-bottom: 20px;
    background: #fff;
}
.dashboardStatCard h3 {
    font-size: 16px;
    font-weight: 500;
}
.bg-black-100{
    background-color: var(--black-100-color) !important;
}
.text-black-60{
    color: var(--black-60-color) !important;
}
.add-transition {
    overflow: hidden;
    height: 0; 
    transition: height 0.3s ease; 
    overflow: hidden;
}
  
.add-transition.expanded {
    height: auto; 
}

.overlay{
    position: fixed;
    left: 0%;
    top: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 50%);
}
.overlayContent{
    position: fixed;
    left: 30%;
    top: 0;
    width: 70%;
    height: 100vh;
    float: right;
    background-color: white;
    transition: all 0.5s;
    z-index: 5;
}

.bank-balance-overlay{
    position: fixed;
    left: 0%;
    top: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 50%);
}

.bank-balance-overlay-flow{
    position: fixed;
    left: 50%;
    top: 0;
    height: 100vh;
    width: 50%;
    background-color: white;
    transition: all 0.5s;
    z-index: 5;
}
.left-100{
    left: 100%;
}
.hover-bg-prime-20:hover{
    background-color: var(--prime-20-color) ;
}
.accordion-button{
    padding: 4px 8px !important;
    background-color: #E2F6ED !important;
    font-weight: 500 !important;
    color: #141313 !important;
    font-size: 14px !important;
}
.accordion-button:focus,
.accordion-button:active {
  box-shadow: none !important;
  border: none !important;
  outline: none !important;
}

/* Hide default Bootstrap arrow */
.accordion-button::after {
    display: none !important;
}

/* Hide Bootstrap's default arrow */
.accordion-button::after {
    display: none !important;
  }
  
  /* Add "+" when closed */
  .accordion-button::before {
    content: "+";
    font-size: 18px;
    margin-right: 10px;
    background-color: red;
    display: none;
  }
  
  /* Change to "-" when expanded */
  .accordion-button[aria-expanded="true"]::before {
    content: "-";
    background-color: red;
    display: none;
  }
  .accordion-item {
    border-radius: 0 !important;
  }
  
  .accordion-header {
    border-radius: 0 !important;
  }
  
  .accordion-button {
    border-radius: 0 !important;
  }
  .accordion-body{
    padding: 0 1rem !important;
  }
  .invoice-hover:hover span{
      color: #5611EB;
      border: 1px solid #5611EB !important;
  }