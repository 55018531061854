.budget_analysis{
    display: flex;
    gap:10px;
    align-items: center;
    border-radius: 14px;
}
.budget_analysis h1{
    font-size: 20px;
    color: #000000;
}
.budget_date_picker input{
    overflow: hidden;
}

.translate-50{
    top: 50%;
    transform: translate( 0 , -50%);
}
#financial_year{
    color: var(--prime-100-color) !important;
    background: transparent;
    text-decoration: underline;
    font-size: 20px;
    border: none;
  }
.add_new_user {
    display: flex;
    justify-content: space-between;
    background: #fff;
    padding: 10px;
    border-radius: 0.25rem;
}

.add_new_user p {
    padding: 0;
    margin: 0;
    color: #000;
    justify-content: center;
    align-items: center;
    display: flex;

}
.box-shadow-filter{
    box-shadow: 0 0 10px 0 #00000024;
}
.form-control:focus {
    outline: none !important;
    box-shadow: none !important;
    border-color: #ccc !important;
  }
  