.user_action{
    display: flex;
    justify-content: space-between;
    gap: 35px;
}

.user_action svg{
    font-size: 15px;
}

.user_action span{
    cursor: pointer;
}

.user_action a{
    color: inherit;
}
.nested-table-container {
    width: 100%;
    margin: 10px auto;
    display: table;
    padding-left: 50px;
    padding-right: 50px
}
.nested-table {
    width: 100%;
    display: table;
}
.nested-table th,
.nested-table td {
    padding: 5px 10px;
    text-align: center;
    border: 1px solid #ccc;
}
